(() => {
    // Get the button element with the ID "submitBtn"
    const btn = document.getElementById("submitBtn");
    
    // Get the form element
    const form = document.querySelector("form");
    
    // Add a click event listener to the button
    btn.addEventListener("click", function (e) {
      // Prevent the default form submission behavior
      e.preventDefault();
      
      // Iterate over all input elements within the form
      form.querySelectorAll("input").forEach(e => {
        // Log the value of each input element to the console
        console.log(e.value);
      });
      
      // Show an alert with a congratulatory message
      alert("well done dear friend \u{1F929}");
    });
  })();
  